import React, { Component } from 'react';
import Modal from './ModalConfiguration'
import Swal from "sweetalert2/dist/sweetalert2.js";
import BannerInfo from './BannerInfo';
import { isMobile } from 'react-device-detect';

class Index extends Component {
    constructor(props) {
        super(props)
        this.token = document.querySelector("[name='csrf-token']").content;
        this.state = {
            modal: false,
            showActions: false,
        }
    }

    toogle = (from) => {
        if (from == "new") {
            this.setState({ modal: true })
        } else {
            this.setState({ modal: false })
        }
    }

    updatePositionQuestion = (e, favorite_module) => {
        fetch(`/settings/update_position/${favorite_module.id}/${e.target.value}/${favorite_module.position}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": this.token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                this.props.loadData();
            });
    }

    delete = id => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/settings/favorite_modules/${id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": this.token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        this.props.loadData()
                    });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.modal && (
                    <Modal
                        //modal props
                        backdrop={"static"}
                        modal={this.state.modal}
                        toggle={this.toogle}
                        title={this.state.modeEdit ? "Actualizar icon" : "Agregar icon"}
                    />
                )}

                <div className="card card-modules-user ui-indexFavorite-container">
                    <div className="card-body">

                        <div className="row">
                            <div className="ui-IndexFavorites-welcome">
                                <h3 style={{ color: "gray" }} className="ui-IndexFavorites-welcome-title">
                                    <b>Bienvenid@ {this.props.current_user.name}</b>
                                    <p style={{ fontSize: '17px', marginTop: '5px', fontWeight: '500' }}>Aquí puedes encontrar tus favoritos que has seleccionado con <i className="fas fa-star icon-gradient bg-mean-fruit ui-IndexFavorites-icon"></i></p>
                                </h3>
                            </div>

                            {this.props.dataModules.length >= 1 && (
                                <div className="ui-IndexFavorite-iconConfig">
                                    <button
                                        className="btn-shadow btn btn-info float-right"
                                        onClick={() => this.setState({ showActions: !this.state.showActions })}
                                    >
                                        <i className={`${!this.state.showActions ? "fas fa-gear" : "fas fa-times"}`}></i>
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* <div className="ui-containerBanner">
                                {isMobile && (
                                    <div className="ui-banner-info-lateral">
                                        <i className="fas fa-wrench"></i>
                                    </div>
                                )}
                                <div className="banner__container">
                                <BannerInfo />
                            </div>
                        </div>                */}

                        {this.props.dataModules.length >= 1 && (
                            <div className="ui-indexFavorite-containerAll">

                                <div className="ui-indexFavorite-imageLeft">
                                    <img src="https://gestionmejora.s3.amazonaws.com/uploads/survey_answer/answer_file/95501/Left.jpg" alt="beewoker" />
                                </div>

                                <div className="ui-indexFavorite-containerAll-cards">
                                    <div className="ui-containerIndexFavorite ui-indexFavorite-containerCards-Principals">
                                        <div className="row justify-content-md-center text-center ui-IndexFavorite-row-Principals">
                                            {this.props.dataModules.map((modulo, index) => (
                                                <div className={`col-md-4 ${index >= 3 ? "mt-3" : ""}`} key={modulo.id}>

                                                    {/* <div className={`card box-shadow-card ${index >= 3 ? "" : "first-three"}`} */}
                                                    <div className={`card box-shadow-card`}
                                                        style={{
                                                            borderLeft: `8px solid ${modulo.color}`,
                                                            borderTop: `3px solid ${modulo.color}`,
                                                            borderRight: `3px solid ${modulo.color}`,
                                                            borderBottom: `3px solid ${modulo.color}`,
                                                            borderRadius: "8px",
                                                            minHeight: '102px'
                                                        }}
                                                    >

                                                        {this.state.showActions && (
                                                            <div style={{ maxWidth: '100%', textAlign: 'right', paddingRight: '15px', paddingTop: '3px' }}>
                                                                <label>Posición</label>
                                                                <select
                                                                    className="select-question"
                                                                    value={modulo.position}
                                                                    onChange={(e) => this.updatePositionQuestion(e, modulo)}
                                                                >
                                                                    {this.props.dataModules.map(item => (
                                                                        <React.Fragment>
                                                                            <option value={item.position}>{item.position}</option>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </select>

                                                                <i className="far fa-trash-alt ml-2 icon-delete" onClick={(e) => this.delete(modulo.id)}></i>
                                                            </div>
                                                        )}

                                                        <div className="card-body ui-indexFavorite-cardBody-Principals">
                                                            <div className="ui-indexFavorite-iconCard-cardP" style={{ color: modulo.color }}>
                                                                <i className={modulo.icon_class}></i>
                                                            </div>
                                                            <div className="ui-indexFavorite-infoCard-cardP">
                                                                <a className='ui-indexFavorite-infoCard-link-cardP'
                                                                    href={modulo.url}
                                                                    data-turbolinks="true"
                                                                    style={{
                                                                        color: modulo.color,
                                                                        textDecoration: "none"
                                                                    }}
                                                                >
                                                                    {modulo.name}
                                                                </a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="ui-indexFavorite-imageRigth">
                                    <img src="https://gestionmejora.s3.amazonaws.com/uploads/survey_answer/answer_file/95502/Right.jpg" alt="beeworker" />
                                </div>
                            </div>
                        )}

                    </div>


                    {this.props.dataModules.length == 0 && (
                        <div className="ui-favoritesIndex-container-imgComments">
                            <div className="row">
                                <div className="ui-favoritesIndex-left">
                                    <img src="https://gestionmejora.s3.amazonaws.com/uploads/survey_answer/answer_file/92547/imagen_Favoritos_Inicio.jpg" />
                                </div>

                                <div className="ui-favoritesIndex-rigth">
                                    <p className="text-favorite">Sí encuentras este <i className="far fa-star icon-gradient bg-mean-fruit ui-IndexFavorites-icon"></i> icono en las páginas de la plataforma. Podrás visualizarlos desde el icono <span>
                                        <button className="btn-open-options btn btn-primary" style={{ borderRadius: '50%', height: '39px', width: '39px', padding: '8px', display: 'inline-flex', justifyContent: 'center' }}>
                                            <i className="fas fa-star" style={{ fontSize: '18px' }}></i>
                                        </button></span> que aparece en el lateral inferior derecho .
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                </div>


            </React.Fragment>
        );
    }
}

export default Index;
